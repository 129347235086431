@import '../../styles/color.scss';

.gm-carousel {
  background-color: $white;
  padding: 1rem 2rem;

  .slide {
    img {
      cursor: pointer;
      filter: grayscale(1);
      filter: opacity(0.5) grayscale(1);
      object-fit: contain;
      pointer-events: visible;

      &:hover {
        filter: none;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .slide {
      align-items: center;
      display: flex;
      justify-content: center;
      max-height: 30rem;

      img, svg {
        width: calc(calc(100%/2) - 2rem);
      }

      div {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
  }

  svg {
    fill: $councilGrey;
    fill: #848484;
    width: 100%;

    .bury-fill {
      fill: $mapGrey;
    }

    &:hover {
      fill: $notBlack;

      .bury-fill {
        fill: $buryBuryBumblebees;
      }
    }
  }

  .control-dots {
    display: none;
  }

  .thumbs-wrapper {
    display: none;
  }
}
