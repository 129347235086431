@import '../../styles/color.scss';

.no-scheme {
  background-color: $white;
  color: $notBlack;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    max-width: $max-width;
    margin: 0 auto;
  }

  .MuiFormControl-root {
    margin-top: 1.5rem;
  }

  .button-group {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    padding-bottom: 2rem;

    .MuiButtonBase-root {
      &:first-child {
        margin-bottom: 2rem;
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row-reverse;
      justify-content: space-evenly;
      margin-top: 1.5rem;
      padding-bottom: 3rem;

      .MuiButtonBase-root {
        width: auto;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
