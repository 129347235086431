@import 'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap';
@import './styles/color.scss';

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  color: $notBlack;
  font-family: 'Lato', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  font-size: 0.875rem;

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;

    .MuiButton-root {
      font-size: 1.5rem;
    }
  }
}

.block {
  &-headline {
    color: $color-primary-body;

    em {
      color: $color-primary;
      font-style: normal;
    }
  }

  &-subtext {
    color: $color-secondary-body;
  }
}

a {
  color: $mushyPea;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: $mushyPea;
  }

  &:not([role='button']) {
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

p {
  line-height: 1.5;
}

h1,
h2,
h3 {
  color: $forestSomething;
}

.page-block {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-open {
    display: flex;
  }
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;

  button {
    margin-top: 1rem;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    button,
    [role='button'] {
      margin-top: 0;
      width: auto;
      min-width: 400px;
    }
  }
}

.page-wrap {
  background-color: $mapGrey;
  display: flex;
  flex: 1;
  flex-direction: column;
}
