@import '../../styles/color.scss';

.introduction {
  h1 {
    color: $notBlack;
    font-size: 2.5rem;
    font-weight: bold;
    padding: 0 1.8rem;
    text-align: center;

    @media screen and (min-width: 768px) {
      font-size: 3.75rem;
      font-weight: 900;
      margin-left: 2rem;
      text-align: left;
    }
  }
}
