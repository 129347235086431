@import '../../styles/color.scss';

.questions {
  background-color: $white;
  counter-reset: question;
  padding: 0 1.5rem 1.5rem 1.5rem;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: $max-width;
  }

  h2 {
    span {
      display: block;

      @media screen and (min-width: 768px) {
        display: inline-block;

        + span {
          margin-left: 1rem;
        }
      }
    }
  }

  &-list {
    padding: 0;

    @media screen and (min-width: 768px) {
      display: flex;
      flex-flow: row wrap;
    }

    li {
      list-style-type: none;

      @media screen and (min-width: 768px) {
        flex: 1 1 calc(calc(100% / 2) - 2rem);
        margin: 0.5rem;
        padding: 0.5rem;
      }

      @media screen and (min-width: 1024px) {
        flex: 1 1 calc(calc(100% / 3) - 2rem);
        margin: 0.5rem;
        padding: 0.5rem;
      }

      h3 {
        color: $notBlack;
        font-size: 1.125rem;
        margin-block-start: 1.6875rem;

        &::before {
          counter-increment: question;
          content: counter(question) '. ';
          display: inline;
        }

        + p {
          color: rgba($notBlack, 40%);
          font-size: 0.8rem;
          margin-block-end: 0.5rem;
          margin-block-start: -1rem;
        }

        @media screen and (min-width: 768px) {
          margin-block-start: 0;
        }
      }
    }

    &-choices {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (min-width: 768px) {
    .button-group {
      flex-direction: row;
    }
  }

  .MuiRadio-colorSecondary.Mui-checked .MuiSvgIcon-root {
    fill: $mushyPea !important;
  }
}
