@import '../../../styles/color.scss';

.options-intro {
  color: $notBlack;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    max-width: $max-width;
    margin: 0 auto;
  }

  &-strap {
    font-size: 1rem;
    font-weight: bold;
    margin-block-end: 3rem;
    @media screen and (min-width: 768px) {
      font-size: 2rem;
    }
  }
}
