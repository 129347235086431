.lightbox {
  &-video {
    position: relative;

    &-overlay {
      align-items: center;
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0 !important;

      svg {
        fill: rgba(0, 0, 0, 0.5);
        width: 3rem;
        height: auto;

        @media screen and (min-width: 768px) {
          width: 6rem;
        }
      }
    }
  }
}
