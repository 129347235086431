@import '../../styles/color.scss';

.about {
  align-items: center;
  background-color: $mapGrey;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-content {
    background-color: $mushyPea;
    color: $notWhite;
    display: flex;
    fill: $notWhite;
    flex-direction: column;
    padding: 1.245rem 1.5rem;
    width: 100%;

    @media screen and (min-width: 768px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      max-width: $max-width;
    }
  }

  &-header {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    margin-block-end: 1.5rem;

    svg {
      height: auto;
      width: 5rem;
      margin-right: 1rem;
    }

    p {
      margin: 0;
      max-width: 20rem;

      @media screen and (min-width: 768px) {
        font-size: 1.1rem;
      }
    }
  }

  &-footer {
    color: inherit;

    p {
      font-size: smaller;
      margin-block-end: 0.25rem;

      @media screen and (min-width: 768px) {
        margin-block-start: 0;
      }
    }

    a {
      align-items: center;
      color: inherit;
      display: flex;
      font-weight: bold;

      &:active,
      &:focus,
      &:hover,
      &:visited {
        color: $carSick;
      }

      .icon {
        margin-right: 0.25rem;
        width: auto;
      }
    }

    .logo {
      fill: $notWhite;
      width: 6rem;

      @media screen and (min-width: 768px) {
        width: 10rem;
      }
    }
  }
}
