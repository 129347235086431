@import '../../styles/color.scss';

.thank-you {
  background-color: $white;
  flex: 1;
  padding: 1.75rem 1.5rem 1.5rem 1.5rem;

  h2 {
    padding-top: 1.5rem;
  }

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: $max-width;
  }

  img {
    width: 100%;
  }

  .button-group {
    padding: 3rem 0;
  }
}
