@import '../../styles/color.scss';

.why-net-zero {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  background-color: $mapGrey;
  position: relative;

  @media screen and (min-width: 768px) {
    align-items: center;
  }

  img {
    background-color: $white;
    padding-top: 3rem;
    width: 100%;
    @media screen and (min-width: 768px) {
      max-width: $max-width;
    }
  }

  h2 {
    color: $forestSomething;
    font-size: 2.25rem;

    span {
      color: $mushyPea;
    }
  }

  h3 {
    font-size: 2rem;
  }

  .MuiButton-fullWidth {
    @media screen and (min-width: 768px) {
      width: auto;
    }
  }

  &-body {
    background-color: $white;
    padding: 1.5rem;

    @media screen and (min-width: 768px) {
      max-width: $max-width;
    }
  }

  .button-group:first-of-type {
    padding: 3rem 0 6rem 0;
  }
}
