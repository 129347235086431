$carSick: #b5db58;
$mushyPea: #7cb834;
$forestSomething: #4b793a;
$deadSalmon: #f3825b;
$notBlack: #262626;
$white: #ffffff;
$notWhite: #eeeeee;
$lightGrey: #eaeaea;
$midGrey: #e1e1e1;
$mapGrey: #f2f2f0;
$councilGrey: #b2b2b2;
$buryBuryBumblebees: #ffde00;

$color-primary: $mushyPea;
$color-primary-body: $notWhite;
$color-primary-body-dark: $notBlack;
$color-primary-body-text: $notBlack;
$color-secondary-body: rgb(158, 158, 158);

$max-width: 920px;
