@import '../../styles/color.scss';

.house-map {
  $markerSize: 4rem;

  align-items: center;
  background-color: $mapGrey;
  display: flex;
  height: 100vw;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (min-width: 768px) {
    height: 30rem;

    &-container {
      max-width: 920px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    width: 1920px;
  }

  &-mask {
    fill: $white;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &-marker.MuiSvgIcon-root {
    fill: $mushyPea;
    height: $markerSize;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate($markerSize * -0.5, $markerSize * -0.925);
    width: $markerSize;
  }
}
