@import '../../styles/color.scss';

.footer-image {
  align-items: center;
  background-color: $mapGrey;
  display: flex;
  justify-content: center;

  img {
    background-color: $white;
    width: 100%;
    @media screen and (min-width: 768px) {
      max-width: $max-width;
    }
  }
}
