@import '../../styles/color.scss';

.home {
  background-color: $mapGrey;
  color: $notBlack;
  position: relative;

  .hero {
    align-items: center;
    background-color: $carSick;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    @media screen and (min-width: 768px) {
      margin: 0 auto 0 auto;
    }

    &-body {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: $max-width;
      position: relative;

      @media screen and (min-width: 768px) {
        flex-direction: row;
      }

      svg {
        fill: $notBlack;
        height: auto;
        margin: 3.625rem 0.1875rem 0 0;
        width: 7.5rem;

        @media screen and (min-width: 768px) {
          margin: 0;
          width: 10rem;
        }
      }
    }

    &-footer {
      display: flex;

      @media screen and (min-width: 768px) {
        display: none;
      }

      img {
        margin-top: -1px;
        width: 100%;
      }
    }
  }


  &-body {
    margin: 0 auto;
    max-width: $max-width;
    position: relative;
  }
}
