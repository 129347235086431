@import '../../styles/color.scss';

.footer {
  background-color: $mapGrey;
  &-content {
    background-color: $forestSomething;
    color: $notWhite;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    margin: 0 auto;
    max-width: $max-width;
    padding: 1.245rem 1.5rem;

    a {
      color: currentColor;
      font-weight: bold;
      padding-bottom: 1rem;
    }
  }
}
