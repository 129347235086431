.options-page {
  display: flex;
  flex-direction: column-reverse;

  .options {
    + .house-map {
      height: 10rem;

      @media screen and (min-width: 768px) {
        height: 10rem;
      }

      .house-map-container {
        max-width: none;
        width: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        overflow: hidden;

        img {
          width: calc(1920px / 2);

          @media screen and (min-width: 768px) {
            width: 100%;
          }
        }
      }
    }
  }
}
