@import '../../styles/color.scss';

.second-choice {
  background-color: $white;
  flex: 1;
  padding: 1rem 1.5rem 1.5rem 1.5rem;
  width: 100%;

  h2 {
    font-size: 1rem;
    color: $notBlack;
  }

  &-back {
    &.MuiPaper-root {
      background-color: $mushyPea;
      padding: 0.75rem;
      color: $white;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
    }

    a {
      color: $white !important;
      margin: 0;
      padding: 0;
    }
  }

  .button-group {
    display: flex;
  }

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: $max-width;

    .button-group {
      margin-top: 1.5rem;

      button {
        width: auto;
      }
    }
  }

  .MuiFormGroup-root {
    margin-bottom: 1.375em;
  }
}
