@import '../../styles/color.scss';

.preamble {
  background-color: $mushyPea;
  font-size: 1.5rem;
  width: 100%;

  &-header {
    color: $notWhite;
    font-size: 1.5rem;
    padding: 1.5rem;

    @media screen and (min-width: 768px) {
      padding: 2.5rem;

      > * {
        margin-left: auto;
        margin-right: auto;
        max-width: $max-width;
      }
    }

    h2 {
      font-size: inherit;
      font-weight: bold;
      margin-top: 2rem;
      color: currentColor;
    }
  }

  &-mask {
    background-color: $white;
    fill: $mushyPea;
  }

  &-footer {
    background-color: $white;
    color: $notBlack;
    padding: 1.5rem;

    @media screen and (min-width: 768px) {
      padding: 2.5rem;

      > * {
        margin-left: auto;
        margin-right: auto;
        max-width: $max-width;
      }
    }

    h3 {
      color: $forestSomething;
      padding-top: 3rem;
      font-size: 2rem;

      &:first-of-type {
        padding-top: 0;
      }
    }
  }
}
