@import '../../styles/color.scss';

.primary-nav {
  display: flex;

  button {
    display: flex;
    flex: 1 1 calc(100% / 3);

    &:first-child {
      justify-content: flex-start;
      padding: 0.3125rem 0 0 0;

      svg {
        height: auto;
        fill: $white;
        width: 5rem;
      }
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  &-root {
    &.MuiButtonBase-root {
      padding-left: 2px;

      svg {
        width: 2rem;
      }
    }
  }

  svg {
    fill: $notWhite;
  }

  &-logo {
    path {
      fill: $carSick;
    }
  }
}
