@import '../../styles/color.scss';

.plan-lookup {
  color: $notBlack;
  display: flex;
  justify-content: center;
  padding: 2rem;
  position: relative;
  width: 100%;

  &-form {
    background-color: #ffffff;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    padding: 1rem;
    width: 100%;

    @media screen and (min-width: 768px) {
      max-width: $max-width;
    }
  }

  h2 {
    color: $forestSomething;

    span {
      color: $mushyPea;
    }
  }

  &-headline {
    font-weight: bold;
  }

  &-subtext {
    color: lighten($notBlack, 40%);
  }

  button {
    margin: 1rem 0;
  }

  &-legal {
    color: lighten($notBlack, 40%);
    font-size: 0.75rem;
    margin: 0.625rem;
  }
}

.page-block {
  align-items: center;
  background-color: rgba($notBlack, 60%);
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}
