@import '../../../styles/color.scss';

.options-table {
  align-items: center;
  background-color: $mapGrey;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .h2 {
    align-self: flex-start;
    background-color: $white;
    flex: 1;
    padding: 1rem;
    width: 100%;

    @media screen and (min-width: 768px) {
      max-width: $max-width;
      padding: 0 1rem;
      margin: 0 auto;
    }

    h2 {
      font-size: 1.6rem;
      color: $notBlack;

      &.light {
        color: rgba(0, 0, 0, 0.6);
      }

      span {
        display: block;
        margin-top: 2rem;
      }
    }
  }

  &-row {
    display: flex;
    background-color: $white;
    flex-direction: row;
    width: 100%;

    @media screen and (min-width: 768px) {
      max-width: $max-width;
      padding: 0 1rem;

      &:first-of-type {
        padding-top: 1rem;
      }
    }

    &-cell {
      border-radius: 0.25rem;
      box-sizing: border-box;
      flex: 1 1 calc(100% / 3);
      font-size: 1rem;
      margin: 0.5rem 0.25rem;
      // max-width: calc(calc(100% / 3) - 0.5rem);

      &-heading {
        background-color: transparent !important;
        flex: 1 1 100%;
        margin-bottom: 0;
        max-width: 100%;
      }

      &-footer {
        background-color: transparent !important;
        color: rgba(0, 0, 0, 0.4);
        flex: 1 1 100%;
        margin-top: 0;
        max-width: 100%;
      }

      &-image {
        padding: 0;

        img {
          border-radius: 0.25rem;
          max-width: 100%;

          + div {
            z-index: 2000;
          }
        }
      }

      &:empty {
        visibility: hidden;
      }
    }

    &-body {
      &-cell {
        background-color: $lightGrey;
        padding: 0.25rem;

        &:nth-child(2n) {
          background-color: $midGrey;
        }

        h2 {
          margin-top: 0;
        }

        h3 {
          margin: 0;
        }

        p:first-child {
          margin-top: 0;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }

      &-modal-controls {
        .options-table-row-cell {
          background-color: $white;
          padding: 0;
        }

        button {
          height: 100%;
          text-transform: capitalize;
        }
      }
    }

    &-head {
      background-color: $white;
      position: sticky;
      top: 3.5rem;
      z-index: 1000;

      @media screen and (min-width: 768px) {
        top: 4rem;
      }

      &-cell {
        .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-fullWidth {
          &:hover {
            background-color: darken($deadSalmon, 10%);
          }
        }
      }
    }
  }

  &-modal {
    &-close {
      &.MuiSvgIcon-root {
        cursor: pointer;
        fill: $notBlack;
        float: right;
        height: auto;
        padding: 1.3rem 2rem 1.3rem 1.3rem;
        position: sticky;
        right: 0;
        top: 0;
        width: 5.3rem;
        z-index: 1000;
        margin-right: -2rem;
      }
    }

    &-body {
      background-color: $white;
      max-width: $max-width;
      padding: 0 2rem 2rem 2rem;
      position: relative;
      width: 100%;

      h1 {
        position: sticky;
        top: 0rem;
        padding-top: 1rem;
        display: block;
        width: 100%;
        background-color: $white;
        margin-top: 0;
      }

      ul {
        padding-inline-start: 1rem;

        li {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
