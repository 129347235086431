@import '../../styles/color.scss';

.loader {
  fill: none;
  height: auto;
  stroke-dasharray: 279;
  stroke-dashoffset: 279;
  stroke-linecap: round;
  stroke-width: 8.5;
  stroke: $notWhite;
  width: 5rem;
  animation: dash 2s linear forwards;
  animation-iteration-count: infinite;
}

@keyframes dash {
  60%,
  100% {
    stroke-dashoffset: 0;
  }
}
