@import '../../styles/color.scss';

.consent {
  color: $notBlack;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    max-width: $max-width;
    margin: 0 auto;
  }

  &-intro {
    font-weight: bold;
  }

  button.MuiButtonBase-root {
    margin-top: 1.5rem;

    &.MuiButton-fullWidth {
      @media screen and (min-width: 768px) {
        display: block;
        width: auto;
      }
    }
  }

  // label {
  //   margin-bottom: 1.5rem;

  //   span:first-child {
  //     align-self: flex-start;
  //     padding-top: 0;
  //   }
  // }
}
