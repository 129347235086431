@import '../../styles/color.scss';

.privacy-policy-page {
  background-color: $white;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    margin: 0 auto;
    max-width: $max-width;
  }

  img {
    width: 100%;
    flex: 1;
  }

  &-company {
    &-logo {
      background-color: $carSick;
      padding: 0 2rem 1rem 1rem;

      @media screen and (min-width: 768px) {
        padding: 0 3rem 1.5rem 1.5rem;
      }

      svg {
        fill: $white;
        height: auto;
        width: 4rem;

        @media screen and (min-width: 768px) {
          width: 8rem;
        }
      }
    }
  }

  &-content {
    padding: 2rem 1rem 1rem 1rem;

    @media screen and (min-width: 768px) {
      padding: 3rem 1.5rem 1.5rem 1.5rem;
    }

    .button-group {
      padding: 2rem 0;

      button,
      [role='button'] {
        width: auto;
      }
    }
  }
}
